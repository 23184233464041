<style>
  svg {
    margin: 0 0.25em 0 0.5em;
  }
</style>

<svg
  width="15px"
  height="16px"
  viewBox="0 0 15 16"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink">
  <g
    id="Notify-Style-Concepts"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <path
      d="M7.06681227,1.92484595 C10.9634297,1.92484595 14.1336806,5.03922755
      14.1336806,8.86724251 C14.1336806,12.6953675 10.9634297,15.8096941
      7.06681227,15.8096941 C3.17019489,15.8096941 1.66977543e-13,12.6953675
      1.66977543e-13,8.86724251 C1.66977543e-13,5.03922755 3.17019489,1.92484595
      7.06681227,1.92484595 Z M7.06681227,13.5248129 C9.68105959,13.5248129
      11.8078517,11.4354643 11.8078517,8.8672425 C11.8078517,8.25643705
      11.6862119,7.67319541 11.4676859,7.13820421 L7.06334005,8.88946962
      L7.06334005,4.20972711 C4.45066084,4.2115977 2.32577285,6.30028608
      2.32577285,8.86724251 C2.32577285,11.4354643 4.45256495,13.5248129
      7.06681227,13.5248129 Z M5.53007392,1.22124533e-14
      L8.61626343,1.22124533e-14 L8.61626343,1.6696743 L5.53007392,1.6696743
      L5.53007392,1.22124533e-14 Z"
      id="transaction-timer"
      fill="#AEAEAE"
      fill-rule="nonzero" />
  </g>
</svg>
